import React, { useState } from 'react';
import styled from 'styled-components';
import GlobalStyle from '../styles/Global';

export const AppContext = React.createContext();
const Global = ({ children }) => {
  const [underline, setUnderline] = useState(null);

  const getOffset = () => {
    const activePage = document.querySelector('.menu [aria-current="page"]');

    if (activePage) {
      setUnderline([activePage.offsetLeft, activePage.offsetWidth]);
    } else {
      setUnderline([0, 0]);
    }
  };

  return (
    <AppContext.Provider
      value={{
        underline,
        setUnderline,
        getOffset,
      }}
    >
      <GlobalFonts>
        <GlobalStyle />
        {children}
      </GlobalFonts>
    </AppContext.Provider>
  );
};

export default Global;

const GlobalFonts = styled.div`
  @font-face {
    font-family: 'House Broken';
    src: url('/fonts/HouseBrokenClean.eot');
    src: url('/fonts/HouseBrokenClean.eot?#iefix') format('embedded-opentype'),
      url('/fonts/HouseBrokenClean.woff') format('woff'),
      url('/fonts/HouseBrokenClean.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;
