export const colors = {
  black: '#010005',
  white: '#ffffff',
  gray: '#e2e2e2',
  lightGray: '#f8f8f8',
  darkGray: '#9d9fa2',
  darkerGray: '#414042',
  green: '#96AD41',
  lightGreen: '#a7c57a',
  gold: '#F6A81C',
  orange: '#F08F00',
  blue: '#1A77B9',
  darkBlue: '#2F3490',
  red: '#b80000',
  text: '#414042',
  blackOverlay: 'rgba(0, 0, 0, .6)',
};

export const font = {
  primary: 'Oswald, sans-serif',
  secondary: 'House Broken, sans-serif',
};

export const spacing = {
  headerHeight: '121px',
  headerMobile: '111px',
  footerHeight: '125px',
  headerAlert: '125px',
};

export const breakpoints = {
  mobile: 480,
  ipadLand: 1024,
  headerBreak: 1140,
  ipadMid: 960,
  midTab: 896,
  ipadPort: 768,
  pageWidth: 970,
  mapBreak1: 1660,
};

export const misc = {
  sectionMargin: 50,
  sectionMobileMargin: 50,
  animSpeed: '.3s',
};
